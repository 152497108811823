﻿@import "../modules/constants";
@import "../modules/mixins";

.navbar-container {
    width: 100%;
    height: auto;

    .navbar-content {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 80%;
        max-width: $breakpoint-xxl;
        margin-top: 30px;
        margin-left: 70px;
        @include respond-min(50px) {
            width: 90%;
        }       

        @include respond-max($breakpoint-sm) {
            margin-left: 30px;
        }

        @include respond-max(375px) {
            margin-top: 10px;
        }

        @include respond-max(575px) {
            flex-direction: column;
        }

        .title-container {
            /*width: 80%;*/
            height: auto;
            margin-right: 30px;

            .company-title {
                color: #000000;
            }

            .title-sub {
            }
        }


        .call-us {
            width: 214px;
            height: 40px;
            background-color: $pink-background;
            color: white;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
            font-size: 15px;

            @include respond-max(575px) {
                margin-top: 30px;
            }
        }

        .call-us:hover {
            background-color: #FF3366;
        }
    }
}
