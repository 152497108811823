﻿
//---------------------------------
//fonts
//---------------------------------

$font-sans: 'Open Sans', 'sans-serif';
$header-main: "Muli", sans-serif;
$paragraph-main: "Montserrat", sans-serif;

//---------------------------------
//colors 
//---------------------------------

$lightest: #FFF;
$light-gray: #E1E1E1;
$light-tan: #FAF9F0;
$hot-pink: #FF2255;
$pink-background: #FF2255;
$lime-green: #5AE995;
//-------------------------
$text-lightest: $lightest;
$light-divider-lines: $light-gray;

//---------------------------------
//breakpoints
//---------------------------------

$breakpoint-xxsm: 320px;
$breakpoint-xsm: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-sml: 1440px;
$breakpoint-xl: 1600px;
$breakpoint-xxl: 1920px;