﻿
@import "../modules/constants";
@import "../modules/mixins";

/* font styles imports */
@import url('https://fonts.googleapis.com/css?family=Muli:300,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

.paragraph-end-link {
	color: #F6490D;
	text-decoration: underline;
}

/* -------------*/
/* TEXT STYLES */
.header-1, .header-2, .header-3, .header-4, .header-light {
    font-family: Muli, sans-serif;
    color: white;
    margin: 0;
}

.header-1 {
	font-weight: 900;

	/*@include respond-min(50px) {
		font-size: 21px;
	}

	@include respond-min($breakpoint-xsm) {
		font-size: 28px;
	}

	@include respond-min($breakpoint-sm) {
		font-size: 35px;
	}*/

	/*@include respond-min($breakpoint-md) {
		font-size: 45px;
	}*/

	@include respond-min($breakpoint-lg) {
		font-size: 55px;
	}

}

.header-2 {
	font-size: 28px;
	font-weight: 900;
	line-height: 28px;

	@include respond-max(531px) {
		/*font-size: 24px;*/
	}

	/*@include respond-min(531px) {
		font-size: 28px;
	}*/
}

.header-3 {
	font-weight: 600;
	font-size: 25px;
}

.header-4 {
	font-weight: bold;

	@include respond-min($breakpoint-xxsm) {
		font-size: 22px;
	}
}

.header-light {
	font-weight: 300;
	letter-spacing: 1.86px;

	@include respond-min($breakpoint-xxsm) {
		font-size: 10px;
	}
}

.paragraph {
    font-size: 18px;
    color: #FAFBFD;
    font-family: Montserrat, sans-serif;
    line-height: 21px;
    margin: 0;
}

p {
	color: #a6b1b9;
	font-size: 18px;
	line-height: 26px !important;
}



/* ----------------------- */
/* BUTTON STYLES */

.btn-xxlarge, .btn-xlarge, .btn-large, .btn-medium, .btn-small {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 22px;
    font-family: Montserrat, sans-serif;
    line-height: 19px;
    font-weight: 500;
    padding: 11px 0;
    outline: none;
    cursor: pointer;
}

.btn-xxlarge {
    width: 245px
}

.btn-xlarge {
    width: 203px;
}

.btn-large {
    width: 140px;
}

.btn-medium {
    width: 122px;
}

.btn-small {
    width: 122px;
    font-size: 9px;
    padding: 4px;
    font-weight: 700;
}

.btn-grey {
    background: white;
}

.btn-pink {
    background-color: $hot-pink;
    color: #FFFFFF;
}

.btn-pink:hover {
    background-color: #F40C41;
    color: #FFFFFF;
}

.btn-white {
    background-color: #FFFFFF;
    color: $hot-pink;
    border: 1px solid $hot-pink;
}

.btn-white:hover {
    // background-color: #F40C41;
    color: $hot-pink;
}

.btn-green {
    background-color: $lime-green;
    color: #FFFFFF;
}

.btn-grey {
    border: 1px solid #999999;
    color: #999999;
}


a:hover {
    color: inherit;
}

.cookie-btn {
    color: white !important;
    background-color: $hot-pink !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    margin-right: 40px !important;

}

.cookie-btn {
    color: white !important;
    background-color: $hot-pink !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    margin-right: 40px !important;
    position: relative;
    @include respond-min(1400px) {
        top: 5px; 
    }
    @include respond-max(1160px) {
        top: 5px; 
    }
    @include respond-max(750px) {
        top: 15px; 
    }
    @include respond-max(500px) {
        margin: 0 auto;
        top: 0px;
        margin-left: 40vw !important;
        
    }
}

.cookie-content {
   
    margin-left: 40px !important;
    @include respond-max(500px) {
        margin-left: 15px !important;
    }
}

.cookie-container {
    opacity: 0.9;

    @include respond-min(1400px) {
        .cookie-content {
            margin-left: 20vw !important;
        }
        .cookie-btn {
            margin-right: 20vw !important;
        }
    }
    @include respond-min(2000px) {
        .cookie-content {
            margin-left: 30vw !important;
        }
        .cookie-btn {
            margin-right: 30vw !important;
        }
    }
}

