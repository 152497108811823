﻿@import "../modules/constants";
@import "../modules/mixins";

.homepage-container {
  width: 100%;
  overflow: hidden;
  /*margin-bottom: 5%;*/
  .navbar-hero-container {
    background-image: url("../images/hero-coming-soon@2x-min.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 62vw;
    width: 100%;
    max-height: 975px;
    background-position: center;

    .homepage-hero-container {
      height: 80%;
      position: relative;
      display: flex;
      justify-content: center;

      @include respond-range(320px, 767px) {
        height: 100%;
      }

      .hero-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 50%;
        width: 80%;

        .mobile-title {
          @include respond-range(768px, 3000px) {
            display: none;
          }
          width: 100%;
          color: #ffffff;
          font-family: Montserrat;
          font-size: 26px;
          font-weight: 600;
          letter-spacing: -0.39px;
          line-height: 29px;
          text-align: center;
        }
      }
    }

    .hero-text-content-containerWeb {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include respond-range(320px, 767px) {
        display: none;
      }

      .hero-title {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 44px;
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: -0.48px;
        line-height: 29px;
        /*text-align: center;*/

        @include respond-range(768px, 1024px) {
          font-size: 26px;
          line-height: 29px;
          margin-bottom: 10px;
        }
      }

      .hero-title-difference {
        color: #353b48;
        font-family: Muli;
        font-size: 56px;
        font-weight: 300;
        line-height: 70px;
        text-align: center;
        margin-top: 15px;

        @include respond-range(768px, 1024px) {
          line-height: 43px;
          font-size: 34px;
        }
      }

      .hero-paragraph {
        color: #353b48;
        font-family: Muli;
        font-size: 56px;
        font-weight: 900;
        line-height: 70px;
        text-align: center;

        @include respond-range(768px, 1024px) {
          line-height: 43px;
          font-size: 34px;
        }
      }

      .hero-btn-sec {
        display: flex;
        justify-content: center;
        margin-top: 3%;

        .hero-btn-pink-button-LearnMore {
          background-color: #ff2255;
          color: #ffffff;
          display: flex;
          width: 170px;
          justify-content: center;
          align-items: center;
          border: none;
          height: 44px;
          border-radius: 22px;
          font-family: Montserrat, sans-serif;
          line-height: 19px;
          font-weight: 500;
          padding: 11px 0;
          outline: none;
          cursor: pointer;
          position: relative;

          .customers-btn-Contact {
            width: 65%;
            color: #ffffff !important;
          }

          .customers-icon_right {
            position: absolute;
            background-image: url("../images/btn-icon-arrowDown@2x.png");
            background-repeat: no-repeat;
            background-size: 80%;
            display: flex;
            height: 20px;
            width: 14px;
            margin-left: 20px;
            right: 22px;
            top: 16px;
          }
        }
      }
    }
  }

  .hero-text-content-containerMob {
    width: 90%;
    margin: auto;
    padding: 8% 0;

    @include respond-range(320px, 767px) {
      padding: 40px 0;
    }

    @include respond-range(768px, 3000px) {
      display: none;
    }

    .hero-title {
      color: #afafaf;
      font-family: Montserrat;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: -0.48px;
      line-height: 29px;
      text-align: center;
    }

    .hero-title-difference {
      color: #353b48;
      font-family: Muli;
      font-size: 34px;
      font-weight: 300;
      line-height: 38px;
      text-align: center;
      margin-top: 15px;
      width: 90%;
      margin: auto;
    }

    .hero-paragraph {
      color: #353b48;
      font-family: Muli;
      font-size: 34px;
      font-weight: 900;
      line-height: 38px;
      text-align: center;
      width: 80%;
      margin: auto;
    }

    .hero-btn-sec {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      .hero-btn-pink-button-LearnMore {
        background-color: #ff2255;
        color: #ffffff;
        display: flex;
        width: 170px;
        justify-content: center;
        align-items: center;
        border: none;
        height: 44px;
        border-radius: 22px;
        font-family: Montserrat, sans-serif;
        line-height: 19px;
        font-weight: 500;
        padding: 11px 0;
        outline: none;
        cursor: pointer;
        position: relative;

        .customers-btn-Contact {
          width: 65%;
          color: #ffffff !important;
        }

        .customers-icon_right {
          position: absolute;
          background-image: url("../images/btn-icon-arrowDown@2x.png");
          background-repeat: no-repeat;
          background-size: 80%;
          display: flex;
          height: 20px;
          width: 14px;
          margin-left: 20px;
          right: 22px;
          top: 16px;
        }
      }
    }
  }

  .mission-statement-container {
    background-image: url("../images/background-tile@1x.png");
    height: 600px;
    width: 100%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    display: flex;
    justify-content: center;

    @include respond-range(320px, 767px) {
      height: auto;
      background-image: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      margin-right: 0;
      margin-top: 10px;
    }

    @include respond-range(768px, 1024px) {
      height: 400px;
      margin-left: 2%;
    }

    .mission-plusIconUp {
      @include respond-range(320px, 767px) {
        width: 100%;
        height: 80px;
        background-image: url("../images/background-tile@1x.png");
        margin-left: 5%;
      }
    }

    .mission-statement {
      width: 84%;
      max-width: 1400px;
      height: 50%;
      background-color: #ffffff;
      margin-top: 6%;
      padding-left: 2%;
      padding-top: 2%;

      @include respond-range(320px, 767px) {
        width: 100%;
        margin-top: 3%;
        padding-left: 0%;
        padding: 0 32px;
        margin-bottom: 3%;
      }

      @include respond-range(768px, 1024px) {
        font-size: 56px;
        line-height: 60px;
        width: 87%;
        margin: auto;
        height: 53%;
      }

      @include respond-range(1025px, 1550px) {
        width: 84%;
        margin: auto;
      }

      .mission-mainStatement {
        @include respond-range(768px, 1024px) {
          padding-right: 10px;
        }

        .mission-Fin {
          width: auto;
          color: #353b48;
          font-family: Muli;
          font-size: 44px;
          font-weight: 900;
          line-height: 55px;

          @include respond-range(320px, 1024px) {
            font-size: 40px;
            line-height: 50px;
          }
        }

        .mission-desc {
          margin-top: 10px;
          width: 100%;
          color: #000000;
          font-family: Montserrat;
          font-size: 26px;
          font-weight: 300;
          line-height: 46px;

          @include respond-range(320px, 767px) {
            font-size: 17px;
            line-height: 29px;
          }

          @include respond-range(768px, 1024px) {
            font-size: 18px;
            line-height: 27px;
            margin-top: 30px;
          }

          .mission-desc-title {
            height: 3px;
            width: auto;
            border-bottom: 3px solid #ff2255;

            @include respond-range(320px, 767px) {
              border-bottom: 2px solid #ff2255;
            }
          }
        }
      }
    }

    .mission-plusIconDown {
      @include respond-range(320px, 767px) {
        width: 100%;
        height: 40px;
        background-image: url("../images/background-tile@1x.png");
        margin-top: 6%;
        margin-left: 5%;
      }
    }
  }

  .high-serviceContainer {
    .highTouch-transition-containerMob {
      @include respond-range(768px, 3000px) {
        display: none !important;
      }

      @include respond-range(320px, 425px) {
        height: 280px;
        max-height: 400px;
        margin-top: 20px;
      }

      @include respond-range(426px, 600px) {
        height: 350px;
        max-height: 400px;
        margin-top: 20px;
      }

      @include respond-range(601px, 767px) {
        height: 450px;
        max-height: 400px;
        margin-top: 20px;
      }

      height: 90%;
      margin-top: 10%;
      height: 40vw;
      max-height: 500px;

      @include respond-range(1501px, 2500px) {
        background-size: cover;
      }

      .highTouch-container-column-right {
        float: right;
        background-color: #fff;
        width: 45%;
        position: relative;
        z-index: 2;
        height: 100%;
        top: 19px;
        left: 1%;
        background-image: url("../images/home-photo-highTouch@2x.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100%;
        background-position-y: top;

        @include respond-range(320px, 767px) {
          left: 0%;
          width: 100%;
          background-size: 100%;
        }

        @include respond-range(1501px, 2500px) {
          background-size: cover;
        }
      }
    }

    .homepage-technology-container {
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: flex;
      justify-content: center;

      @include respond-range(320px, 767px) {
        margin-top: 40px;
      }

      .tech-content-container {
        width: 100%;

        .tech-text-background-container {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          z-index: 0;

          @include respond-range(320px, 767px) {
            position: unset;
          }

          .tech-text-content {
            width: 77%;
            margin-top: 18%;
            max-width: 1400px;

            @include respond-range(320px, 767px) {
              width: 100%;
              margin-top: 0;
              padding: 0 32px;
            }

            @include respond-range(768px, 1024px) {
              width: 90%;
              margin-top: 11%;
            }

            .high-touchText {
              color: #452a49;
              font-family: Muli;
              font-size: 38px;
              font-weight: 800;
              line-height: 46px;

              @include respond-range(320px, 1024px) {
                font-size: 32px;
                line-height: 42px;
              }
            }

            .tech-text-paragraphs {
              width: 48%;
              color: #000000;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 300;
              line-height: 29px;
              margin-top: 3%;

              @include respond-range(320px, 767px) {
                width: 100%;
                font-size: 16px;
                line-height: 27px;
              }

              @include respond-range(768px, 1024px) {
                font-size: 16px;
                line-height: 27px;
              }

              .high-touch-LearnMore {
                margin-top: 5%;
              }

              .paragraph-end-link {
                color: #ff2255;
                font-size: 17px;
                line-height: 29px;
                text-decoration: underline;
                cursor: pointer;
              }

              .paragraph-end-link:hover {
                color: #f40c41;
              }
            }
          }
        }

        .highTouch-transition-containerWeb {
          @include respond-range(320px, 767px) {
            display: none !important;
          }

          height: 90%;
          margin-top: 10%;
          height: 40vw;
          max-height: 500px;

          @include respond-range(1501px, 2500px) {
            background-size: cover;
          }

          .highTouch-container-column-right {
            float: right;
            background-color: #fff;
            width: 45%;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 19px;
            left: 1%;
            background-image: url("../images/home-photo-highTouch@2x.jpg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 100%;
            background-position-y: top;

            @include respond-range(1501px, 2500px) {
              background-size: cover;
            }
          }
        }
      }
    }
  }

  .modern-values-container {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    max-width: 1400px;
    margin: auto;
    padding-top: 10%;
    padding-bottom: 2%;

    @include respond-range(320px, 767px) {
      margin-bottom: 50px;
    }

    .modern-values-content {
      width: 60%;

      @include respond-range(768px, 1024px) {
        width: 80%;
      }

      @include respond-range(320px, 767px) {
        width: 100%;
      }

      .modern-value-row {
        display: flex;
        justify-content: center;

        .values-header-container {
          .lighter-header {
            color: #452a49;
            font-family: Muli;
            font-size: 38px;
            font-weight: 500;
            line-height: 46px;
            text-align: center;
          }

          .brighter-header {
            color: #452a49;
            font-family: Muli;
            font-size: 38px;
            font-weight: 900;
            line-height: 46px;
            text-align: center;

            @include respond-range(320px, 767px) {
              font-size: 32px;
            }
          }
        }
      }

      .User {
        margin-top: 10%;

        @include respond-range(320px, 767px) {
          margin-top: 50px;
        }

        @include respond-range(768px, 1024px) {
          height: 210px;
          margin-top: 10%;
        }
      }

      .Automated {
        margin-top: 5%;

        @include respond-range(320px, 767px) {
          margin-top: 50px;
        }

        @include respond-range(768px, 1024px) {
          height: 210px;
          margin-top: 0%;
        }
      }

      .modernTech-ImageText {
        width: 100%;
        height: 150px;

        @include respond-range(320px, 767px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: auto;
        }

        .modernTech-Image {
          width: 20%;
          float: left;
          height: 100%;

          @include respond-range(320px, 767px) {
            width: 60px;
            height: 70px;
          }

          .iconExperience {
            background-image: url("../images/icon-ux@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 70px;
            width: 65px;
            margin-top: 5px;
          }

          .Automated {
            background-image: url("../images/icon-automated@2x.png");
            margin-top: 0px;
          }

          .Seamless {
            background-image: url("../images/icon-seamless@2x.png");
            margin-top: 5px;
          }
        }

        .modernTech-Text {
          width: 80%;
          float: left;
          height: 100%;

          @include respond-range(320px, 767px) {
            width: 100%;
            margin-top: 30px;
            padding: 0 32px;
          }

          .modernTechHeader {
            color: #34485e;
            font-family: Muli;
            font-size: 26px;
            font-weight: bold;
            line-height: 33px;

            @include respond-range(320px, 767px) {
              font-size: 22px;
              text-align: center;
            }
          }

          .value-paragraph {
            color: #000000;
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 300;
            line-height: 29px;
            margin-top: 2%;

            @include respond-range(320px, 767px) {
              font-size: 16px;
              line-height: 27px;
              text-align: center;
              margin-top: 10px;
            }
          }

          .paragraph-end-link {
            color: #ff2255;
            font-family: Montserrat;
            font-size: 17px;
            line-height: 29px;
            text-decoration: underline;
            margin-top: 4%;
            cursor: pointer;

            @include respond-range(320px, 767px) {
              display: flex;
              justify-content: center;
            }
          }

          .paragraph-end-link:hover {
            color: #f40c41;
          }
        }
      }
    }
  }

  .customer-serviceContainer {
    height: 50vw;
    max-height: 900px;

    @include respond-range(320px, 767px) {
      height: auto;
      max-height: unset;
      margin-bottom: 50px;
    }

    @include respond-range(768px, 800px) {
      height: 65vw;
      max-height: 740px;
      margin-top: 5%;
    }

    @include respond-range(801px, 1200px) {
      height: 60vw;
      max-height: 700px;
      margin-top: 5%;
    }

    .customer-experience-container {
      width: 100%;
      height: 50vw;
      margin-top: 0;
      display: flex;
      justify-content: center;

      @include respond-range(320px, 767px) {
        height: 100%;
      }

      .customer-content-container {
        width: 100%;

        @include respond-range(320px, 767px) {
          display: flex;
          flex-direction: column;
        }

        .customer-text-background-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 52%;
          z-index: 0;
          height: 50vw;

          @include respond-range(320px, 767px) {
            width: 100%;
            height: auto;
            margin-top: 50px;
          }

          .customer-text-content {
            width: 62%;
            margin-top: 15%;
            position: relative;
            left: -4%;
            margin: auto;

            @include respond-range(320px, 767px) {
              width: 100%;
              left: 0;
              padding: 0 32px;
            }

            @include respond-range(768px, 1024px) {
              width: 80%;
              left: 0;
            }

            @include respond-range(1025px, 1200px) {
              margin-top: 30%;
            }

            .customer-header-container {
              .customer-lighter-header {
                color: #452a49;
                font-family: Muli;
                font-size: 38px;
                font-weight: 500;
                line-height: 46px;
                text-align: left;

                @include respond-range(320px, 1024px) {
                  font-size: 32px;
                  line-height: 38px;
                }
              }

              .customer-brighter-header {
                color: #452a49;
                font-family: Muli;
                font-size: 38px;
                font-weight: 900;
                line-height: 46px;
                text-align: left;

                @include respond-range(320px, 767px) {
                  font-size: 32px;
                  line-height: 38px;
                }
              }
            }

            .customer-text-paragraphs {
              width: 100%;
              color: #000000;
              font-family: Montserrat;
              font-size: 17px;
              font-weight: 300;
              line-height: 29px;
              margin-top: 15px;

              @include respond-range(320px, 1024px) {
                font-size: 16px;
                line-height: 27px;
              }

              .customer-touch-LearnMore {
                margin-top: 10px;
              }

              .paragraph-end-link {
                color: #ff2255;
                font-size: 17px;
                line-height: 29px;
                text-decoration: underline;
                margin-top: 4%;
                cursor: pointer;
              }

              .paragraph-end-link:hover {
                color: #f40c41;
              }
            }
          }
        }

        .customer-transition-container {
          height: 80%;
          width: 48%;
          float: left;

          @include respond-range(320px, 767px) {
            width: 100%;
            position: relative;
            height: unset;
          }

          @include respond-range(768px, 1024px) {
            height: 90%;
          }

          .customer-container-column-left {
            float: left;
            background-color: #fff;
            width: 100%;
            position: relative;
            z-index: 2;
            height: 70%;
            top: 160px;
            background-image: url("../images/home-photo-customer@2x.jpg");
            background-repeat: no-repeat;
            background-size: 140%;
            background-position: 100%;
            background-position-y: top;

            @include respond-range(320px, 500px) {
              top: 0;
              max-height: 260px;
              min-height: 235px;
            }

            @include respond-range(501px, 600px) {
              top: 0;
              max-height: 260px;
              min-height: 350px;
            }

            @include respond-range(601px, 767px) {
              top: 0;
              max-height: 400px;
              min-height: 440px;
            }

            @include respond-range(768px, 1024px) {
              height: 90%;
              top: 12%;
            }
          }
        }
      }
    }
  }

  .services-types-container {
    background-color: #fdf7f5;
    height: 950px;

    @include respond-range(320px, 767px) {
      margin-top: 0%;
      height: auto;
      padding-bottom: 90px;
    }

    @include respond-range(768px, 1550px) {
      height: 830px;
    }

    .services-content-container {
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: flex;
      justify-content: center;

      .services-contentMain-container {
        width: 100%;

        .services-text-background-container {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          z-index: 0;
          height: 100%;
          margin-top: 10%;
          padding-top: 1%;

          @include respond-range(320px, 767px) {
            flex-direction: column;
            width: 100%;
            margin-top: 50px;
            position: unset;
          }

          .services-text-content {
            width: 78%;
            position: relative;
            max-width: 1400px;

            @include respond-range(320px, 767px) {
              width: 100%;
            }

            @include respond-range(768px, 1024px) {
              width: 90%;
            }

            .services-header-container {
              margin-top: 0%;
              position: absolute;
              top: 20%;

              @include respond-range(320px, 767px) {
                width: 100%;
                padding: 0 32px;
                margin: auto;
                position: unset;
              }

              .services-brighter-header {
                color: #452a49;
                font-family: Muli;
                font-size: 38px;
                font-weight: 900;
                line-height: 46px;

                @include respond-range(320px, 1024px) {
                  font-size: 32px;
                  line-height: 38px;
                }
              }

              .services-lighter-header {
                color: #452a49;
                font-family: Muli;
                font-size: 38px;
                font-weight: 300;
                line-height: 46px;
                text-align: left;

                @include respond-range(320px, 1024px) {
                  font-size: 32px;
                  line-height: 38px;
                }
              }
            }

            .services-plusIconsUp {
              position: absolute;
              height: 44%;
              width: 45%;
              top: -26%;
              background-image: url("../images/background-tile@1x.png");
              left: 0%;

              @include respond-range(320px, 767px) {
                display: none;
              }

              @include respond-range(768px, 1024px) {
                left: 0%;
                width: 45%;
                top: -33%;
                height: 46%;
              }
            }

            .services-plusIconsDown {
              position: absolute;
              height: 47%;
              width: 45%;
              top: 39%;
              background-image: url("../images/background-tile@1x.png");
              left: 0%;

              @include respond-range(320px, 767px) {
                display: none;
              }

              @include respond-range(768px, 1024px) {
                left: 0%;
                width: 45%;
              }
            }
          }

          .services-titleEscrow-containerMob {
            width: 100%;
            height: 100%;
            float: right;

            @include respond-range(768px, 3000px) {
              display: none !important;
            }

            .services-titleEscrow-Text {
              float: right;
              width: 34%;
              position: relative;
              z-index: 2;
              height: 100%;
              top: 19px;
              right: 10%;
              margin-top: 10%;

              @include respond-range(320px, 767px) {
                width: 100%;
                padding: 0 32px;
                right: 0;
                margin-top: 15px;
              }

              .services-titleIns {
                color: #34485e;
                font-family: Muli;
                font-size: 26px;
                font-weight: bold;
                line-height: 33px;

                @include respond-range(320px, 767px) {
                  font-size: 22px;
                  line-height: 33px;
                  margin-top: 20px;
                }
              }

              .services-titleIns-Des {
                color: #afafaf;
                font-family: Montserrat;
                font-size: 17px;
                font-weight: 300;
                line-height: 29px;
                margin-top: 3%;

                @include respond-range(320px, 767px) {
                  font-size: 16px;
                  line-height: 27px;
                  margin-top: 10px;
                }
              }

              .services-paragraph-end-link {
                text-decoration: underline;
                color: #798fb9;
                font-family: Montserrat;
                font-size: 16px;
                line-height: 29px;
                cursor: pointer;
              }

              .services-titleEscrow-TextBottom {
                width: 100%;
                float: right;
                position: relative;
                z-index: 2;
                height: 40%;
                top: 19px;
                right: 8%;
                left: 0%;
                margin-top: 14%;

                @include respond-range(320px, 767px) {
                  margin-top: 0;
                }

                @include respond-range(1024px, 1200px) {
                  margin-top: 18%;
                }
              }
            }
          }
        }
      }

      .services-titleEscrow-containerWeb {
        width: 100%;
        height: 100%;
        float: right;
        max-width: 1400px;
        margin: auto;

        @include respond-range(320px, 767px) {
          float: left;
          display: none !important;
        }

        @include respond-range(768px, 3000px) {
          float: none;
        }

        .services-titleEscrow-Text {
          float: right;
          width: 34%;
          position: relative;
          z-index: 2;
          height: 100%;
          top: 19px;
          right: 0%;
          margin-top: 10%;

          @include respond-range(320px, 767px) {
            width: 80%;
            margin-top: 5%;
          }

          @include respond-range(768px, 1024px) {
            right: 5%;
            width: 42%;
          }

          @include respond-range(1025px, 1700px) {
            right: 10%;
          }

          @include respond-range(1701px, 3000px) {
            right: 8%;
          }

          .services-titleIns {
            color: #34485e;
            font-family: Muli;
            font-size: 26px;
            font-weight: bold;
            line-height: 33px;

            @include respond-range(320px, 1024px) {
              font-size: 22px;
              line-height: 33px;
            }
          }

          .services-titleIns-Des {
            color: #afafaf;
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 300;
            line-height: 29px;
            margin-top: 3%;

            @include respond-range(320px, 1024px) {
              font-size: 16px;
              line-height: 27px;
            }
          }

          .services-paragraph-end-link {
            text-decoration: underline;
            color: #798fb9;
            font-family: Montserrat;
            font-size: 16px;
            line-height: 29px;
            cursor: pointer;
          }

          .services-titleEscrow-TextBottom {
            width: 100%;
            float: right;
            position: relative;
            z-index: 2;
            height: 40%;
            top: 19px;
            right: 8%;
            left: 0%;
            margin-top: 14%;

            @include respond-range(320px, 767px) {
              margin-top: 0;
            }

            @include respond-range(1024px, 1200px) {
              margin-top: 18%;
            }
          }
        }
      }
    }
  }

  .customers-serve-ImgContainerMob {
    width: 100%;
    height: 122vw;
    margin-top: -11%;

    @include respond-range(768px, 3000px) {
      display: none;
    }

    .customers-customers-Image {
      float: right;
      width: 100%;
      z-index: 2;
      height: 100%;
      margin-top: 0%;

      .customer-container-column-right {
        float: left;
        width: 100%;
        z-index: 2;
        height: 110%;
        background-image: url("../images/Bitmap@2x.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        background-position-y: top;
      }
    }
  }

  .customers-types-container {
    background-color: #353b48;
    height: auto;

    @include respond-range(768px, 880px) {
      height: 130vw;
      max-height: 940px;
    }

    @include respond-range(881px, 1024px) {
      height: 130vw;
      max-height: 900px;
    }

    @include respond-range(1025px, 1440px) {
      height: 135vw;
      max-height: 980px;
    }

    @include respond-range(1441px, 3000px) {
      height: 140vw;
      max-height: 900px;
    }

    .customers-content-container {
      max-height: 900px;
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: flex;
      justify-content: center;

      @include respond-range(320px, 767px) {
        max-height: unset;
      }

      .customers-contentMain-container {
        width: 100%;

        .customers-text-background-container {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          z-index: 0;
          margin-top: 9%;

          @include respond-range(320px, 767px) {
            height: 100%;
            width: 100%;
            margin-top: 15%;
            position: unset;
            margin-top: 50px;
            margin-bottom: 50px;
          }

          @include respond-range(1441px, 3000px) {
            margin-top: 100px;
          }

          .customers-text-content {
            width: 78%;
            max-width: 1400px;

            @include respond-range(320px, 767px) {
              width: 100%;
              padding: 0 32px;
            }

            @include respond-range(768px, 1024px) {
              width: 90%;
            }

            .customers-header-container {
              width: 65%;

              @include respond-range(320px, 767px) {
                width: 100%;
              }

              @include respond-range(768px, 1024px) {
                width: 65%;
              }

              @include respond-range(1024px, 1475px) {
                width: 70%;
              }

              .customers-brighter-header {
                color: #ffffff;
                font-family: Muli;
                font-size: 38px;
                font-weight: 900;
                line-height: 46px;

                @include respond-range(320px, 767px) {
                  width: 65%;
                  font-size: 32px;
                  line-height: 38px;
                }

                @include respond-range(768px, 1024px) {
                  font-size: 32px;
                  line-height: 38px;
                }
              }

              .customers-lighter-header {
                color: #ffffff;
                font-family: Muli;
                font-size: 38px;
                font-weight: 300;
                line-height: 46px;

                @include respond-range(320px, 767px) {
                  font-size: 32px;
                  line-height: 38px;
                }
              }

              .customers-serve-sec {
                height: 500px;
                width: 100%;
                padding: 80px 0px;

                @include respond-range(320px, 767px) {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  padding-top: 40px;
                  padding-bottom: 40px;
                }

                @include respond-range(768px, 1024px) {
                  padding: 10% 0;
                }

                .customers-serve-sec-left {
                  height: 100%;
                  width: 42%;
                  float: left;
                  margin-right: 8%;

                  @include respond-range(320px, 767px) {
                    margin-right: 0%;
                    width: 100%;
                  }

                  @include respond-range(320px, 767px) {
                    margin-right: 0%;
                    width: 100%;
                  }

                  .customers-serve-section {
                    color: #ffffff;
                    font-family: Muli;
                    font-size: 26px;
                    font-weight: bold;
                    line-height: 33px;

                    @include respond-range(320px, 1024px) {
                      font-size: 22px;
                      line-height: 28px;
                    }

                    .customers-serve-des {
                      color: #cfcfcf;
                      font-family: Montserrat;
                      font-size: 17px;
                      font-weight: 300;
                      line-height: 29px;
                      margin-top: 6%;

                      @include respond-range(320px, 1024px) {
                        font-size: 16px;
                        line-height: 27px;
                        margin-top: 15px;
                      }
                    }
                  }

                  .btn-pink-button-LearnMoreWeb {
                    margin-top: 22%;
                    background-color: #ff2255;
                    color: #ffffff;
                    display: flex;
                    width: 170px;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 22px;
                    font-family: Montserrat, sans-serif;
                    line-height: 19px;
                    font-weight: 500;
                    padding: 11px 0;
                    outline: none;
                    cursor: pointer;
                    position: relative;

                    @include respond-range(320px, 767px) {
                      margin: auto;
                      display: none;
                    }

                    @include respond-range(768px, 1024px) {
                      margin-top: 26%;
                    }

                    .customers-btn-Contact {
                      width: 70%;
                      color: #ffffff !important;
                    }

                    .customers-icon_right {
                      position: absolute;
                      background-image: url("../images/btn-icon-arrowRight@2x.png");
                      background-repeat: no-repeat;
                      background-size: 100%;
                      display: flex;
                      height: 20px;
                      width: 14px;
                      margin-left: 20px;
                      right: 20px;
                      top: 14px;
                    }
                  }

                  .btn-pink-button-LearnMoreWeb:hover {
                    background-color: #f40c41;
                  }

                  .services {
                    margin-top: 20%;

                    @include respond-range(320px, 767px) {
                      margin-top: 40px;
                    }
                  }
                }

                .customers-serve-sec-right {
                  height: 100%;
                  width: 40%;
                  float: left;

                  @include respond-range(320px, 767px) {
                    margin-top: 40px;
                    width: 100%;
                  }

                  .customers-serve-section {
                    color: #ffffff;
                    font-family: Muli;
                    font-size: 26px;
                    font-weight: bold;
                    line-height: 33px;

                    @include respond-range(320px, 1024px) {
                      font-size: 22px;
                      line-height: 28px;
                    }

                    .customers-serve-des {
                      color: #cfcfcf;
                      font-family: Montserrat;
                      font-size: 17px;
                      font-weight: 300;
                      line-height: 29px;
                      margin-top: 6%;

                      @include respond-range(320px, 1024px) {
                        font-size: 16px;
                        line-height: 27px;
                        margin-top: 15px;
                      }
                    }
                  }

                  .services {
                  }
                }
              }

              .btn-pink-button-LearnMoreMob {
                margin-top: 5%;
                background-color: #ff2255;
                color: #ffffff;
                display: flex;
                width: 170px;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 22px;
                font-family: Montserrat, sans-serif;
                line-height: 19px;
                font-weight: 500;
                padding: 11px 0;
                outline: none;
                cursor: pointer;
                position: relative;

                @include respond-range(768px, 3000px) {
                  display: none;
                }

                .customers-btn-Contact {
                  width: 70%;
                  color: #ffffff !important;
                }

                .customers-icon_right {
                  position: absolute;
                  background-image: url("../images/btn-icon-arrowRight@2x.png");
                  background-repeat: no-repeat;
                  background-size: 100%;
                  display: flex;
                  height: 20px;
                  width: 14px;
                  margin-left: 20px;
                  right: 20px;
                  top: 14px;
                }
              }

              .btn-pink-button-LearnMore:hover {
                background-color: #f40c41;
              }
            }
          }
        }
      }

      .customers-serve-ImgContainerWeb {
        width: 100%;
        height: 58vw;
        max-width: 1400px;
        margin: auto;

        @include respond-range(320px, 767px) {
          display: none;
        }

        .customers-customers-Image {
          float: right;
          width: 32%;
          position: relative;
          z-index: 2;
          height: 65%;
          top: 19px;
          right: 7%;
          margin-top: 8%;

          @include respond-range(768px, 1024px) {
            right: 5%;
            width: 35%;
            top: 15%;
            height: 90%;
          }

          @include respond-range(1600px, 1700px) {
            right: 4%;
          }

          @include respond-range(1701px, 1800px) {
            right: 2%;
          }

          @include respond-range(1801px, 3000px) {
            right: 0%;
          }

          .customer-container-column-right {
            float: left;
            width: 100%;
            position: relative;
            z-index: 2;
            height: 87%;
            background-image: url("../images/Bitmap@2x.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 39%;
            background-position-y: top;
          }
        }
      }
    }
  }
}

.aboutHiring-Sec {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;

  .icon_right {
    background-image: url("../images/btn-icon-arrowRight@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 15px;
    height: 15px;
  }
}

.contactForm-container {
  margin: 100px 0;

  @include respond-range(320px, 767px) {
    margin: 60px 0;
  }

  @include respond-range(768px, 1024px) {
    /*margin-top: 40px;*/
    /*margin-bottom: 50px;*/
  }

  .contactForm-Note {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 837px;
    height: auto;
    margin: auto;
    /*margin-top: 30%;*/
    flex-direction: column;
    position: relative;

    @include respond-range(320px, 767px) {
      height: auto;
      width: 100%;
      /*margin-top: 0;*/
    }

    @include respond-range(768px, 1024px) {
      width: 90%;
      /*margin-top: 0;*/
    }

    .resp-message {
      position: absolute;
      bottom: 291px;
      color: #353b48;
      font-weight: 700;
      text-align: center;
      width: 90%;
    }

    .noteText {
      color: #353b48;
      font-size: 44px;
      line-height: 46px;

      @include respond-range(320px, 767px) {
        font-size: 32px;
        line-height: 42px;
      }
    }

    .contact-inputNoteSec {
      width: 100%;
      display: flex;
      margin-top: 10%;

      @include respond-range(320px, 767px) {
        flex-direction: column;
        margin-top: 15px;
      }

      @include respond-range(768px, 1024px) {
        margin-top: 30px;
      }

      .contact-inputNoteSecLeft {
        width: 40%;
        margin-right: 40px;

        @include respond-range(320px, 600px) {
          width: 100%;
          margin: auto;
          padding: 0 32px;
        }

        @include respond-range(601px, 767px) {
          width: 60%;
          margin: auto;
        }

        @include respond-range(768px, 1024px) {
          width: 50%;
          margin-right: 5%;
        }

        .name-input {
          height: 44px;
          width: 340px;
          padding-left: 10px;
          font-family: Montserrat;
          font-size: 14px;
          line-height: 18px;
          border: 1px solid #ebebeb;
          border-radius: 3px 0 0 3px;
          background-color: #ffffff;
          margin-bottom: 7%;

          @include respond-range(320px, 767px) {
            width: 100%;
            margin-bottom: 30px;
          }

          @include respond-range(768px, 1024px) {
            width: 100%;
          }
        }

        .dropdown {
          height: auto;
        }
      }

      .contact-inputNoteSecRight {
        width: 60%;

        @include respond-range(320px, 600px) {
          width: 100%;
          padding: 0 32px;
        }

        @include respond-range(601px, 767px) {
          width: 60%;
          margin: auto;
        }

        @include respond-range(768px, 1024px) {
          width: 50%;
        }

        .contact-TextArea {
          resize: none;
          box-sizing: border-box;
          height: 182px;
          width: 462px;
          border: 1px solid #ebebeb;
          border-radius: 3px;
          background-color: #ffffff;
          padding: 15px;

          @include respond-range(320px, 767px) {
            width: 100%;
          }

          @include respond-range(768px, 1024px) {
            width: 100%;
          }
        }
      }
    }
  }
}
