﻿@import "../modules/constants";
@import "../modules/mixins";

.footer-container {
  height: 350px;

  .footer-content-container {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    justify-content: center;

    .footer-contentMain-container {
      width: 100%;
      height: 3px;
      background-color: #f5f5f5;

      .footer-text-background-container {
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        z-index: 0;
        margin-top: 5%;

        @include respond-range(320px, 767px) {
          margin-bottom: 10%;
        }

        .footer-text-content {
          /*width: 100%;*/
          width: 78%;
          display: flex;
          position: relative;
          max-width: 1400px;

          @include respond-range(320px, 767px) {
            width: 100%;
            padding: 0 16px;
            flex-direction: column;
          }

          @include respond-range(768px, 1024px) {
            width: 90%;
          }

          .footer-text-contentLeft {
            width: 50%;

            @include respond-range(320px, 767px) {
              width: 100%;
            }

            .header-2,
            .header-3,
            .header-light {
              color: #152935;
            }

            .footer-Fin-Text {
              color: #353b48;
              font-family: "Montserrat", sans-serif;
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              margin-top: 5px;

              @include respond-range(320px, 767px) {
                width: 100%;
              }
            }

            .footer-inputText {
              margin-top: 7%;
              display: none;

              .footer-placeHolder {
                height: 44px;
                width: 60%;
                font-family: Montserrat;
                font-size: 14px;
                line-height: 18px;
                border: 1px solid #ebebeb;
                border-radius: 3px 0 0 3px;
                background-color: #ffffff;
                padding-left: 2%;

                @include respond-range(320px, 767px) {
                  width: 90%;
                }

                @include respond-range(768px, 1024px) {
                  width: 75%;
                }

                @include respond-range(1024px, 1200px) {
                  font-size: 11px;
                }
              }
            }
          }

          .footer-page-section {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @include respond-range(320px, 767px) {
              width: 100%;
            }

            .footer-section-right {
              .footer-pageNav {
                color: #353b48;
                font-family: Montserrat;
                font-size: 15px;
                height: 33px;
                line-height: 50px;
                font-weight: 500;
                cursor: pointer;
                text-align: right;

                @include respond-range(320px, 767px) {
                  font-size: 12px;
                }

                @include respond-range(360px, 767px) {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .footer-bottomToTop {
          border-radius: 50%;
          margin-top: 32px;

          .footer-bottomToTopIcon {
            border-radius: 50%;
            background-color: #ff2255;
            cursor: pointer;
            width: 45px;
            height: 45px;
            background-image: url("../images/btn-icon-arrowUp@2x.png");
            background-repeat: no-repeat;
            background-size: 30%;
            background-position: center;
          }
        }

        .footer-bottomToTop:hover {
          background-color: #f40c41;
        }

        .footer-allRightsWeb {
          color: #afafaf;
          margin-top: 20px;

          @include respond-max(767px) {
            display: none;
          }
        }

        .footer-allRightsMob {
          width: 100%;
          margin-top: 5%;
          color: #afafaf;
          font-family: Montserrat;
          font-size: 12px;
          line-height: 21px;
          text-align: center;

          @include respond-min(768px) {
            display: none;
          }
        }

        .footer_rightsMob {
          @include respond-range(768px, 3000px) {
            display: none;
          }
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 12px;

          .footer-textPolicy {
            cursor: pointer;
            text-decoration: underline;
            color: #afafaf;
            font-size: 12px;
          }
          .Privacy {
            margin-right: 3%;
          }
        }
        .footer-textPolicyMob {
          width: 100%;
          display: flex;
          cursor: pointer;
          text-decoration: underline;
          color: #afafaf;
          margin-top: 4px;
          font-size: 12px;
          justify-content: center;
          @include respond-range(768px, 3000px) {
            display: none;
          }
        }
        .footer_rights {
          display: flex;
          margin-top: 12px;

          @include respond-range(320px, 767px) {
            display: none;
          }
          .footer-textPolicy {
            cursor: pointer;
            text-decoration: underline;
            color: #afafaf;
            margin-right: 3%;
            font-size: 14px;
          }
        }
      }
    }
  }
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbbbbb;
}

::-ms-input-placeholder {
  color: #bbbbbb;
}

input:focus {
  outline: none;
}
